<!--商品出入库统计表-->
<template>
  <div class="statement_box">
    <div 
      v-loading="dateLoading" 
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      class="statement_top_date">
      <div class="statement_date_query">
        <div class="statement_date_query_left">
            <!--年-->
          <el-select v-model="yearValue" placeholder="年" @change="handleDateChange('year')">
            <el-option
              v-for="item in yearOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <!--季度-->
          <el-select v-model="quarterValue" placeholder="季" @change="handleDateChange('quarter')">
            <el-option
              v-for="item in quarterOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <!--月-->
          <el-select v-model="monthValue" placeholder="月" @change="handleDateChange('month')">
            <el-option
              v-for="item in monthOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <!--天-->
          <el-select v-model="dayValue" placeholder="日" @change="handleDateChange('day')">
            <el-option
              v-for="item in dayOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-button type="primary" size="small" :plain="isAll" @click="handleSearchBtn">全部</el-button>
          <el-date-picker
            class="date_range"
            v-model="pickerValue"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="onDatePickerChange">
          </el-date-picker>
        </div>
        <div class="statement_date_query_right">
          <div class="statement_explain">统计说明<i class="el-icon-question"></i></div>
          <el-button plain size="small" @click="exportStatement">导出</el-button>
        </div>
      </div>
      <div class="statement_date_content">
        <div class="statement_date_content_row">
          <div class="statement_date_content_col">
            <div class="statement_date_label">期初成本金额</div>
            <div class="statement_date_val">{{divisionMoney(collectData.initialStockPrice)}}</div>
          </div>
        </div>
        <div class="statement_date_content_row">
          <div class="statement_date_content_col">
            <div class="statement_date_label">入库成本金额</div>
            <div class="statement_date_val">{{divisionMoney(collectData.putStockPrice)}}</div>
          </div>
        </div>
        <div class="statement_date_content_row">
          <div class="statement_date_content_col">
            <div class="statement_date_label">出库成本金额</div>
            <div class="statement_date_val">{{divisionMoney(collectData.outStockPrice)}}</div>
          </div>
        </div>
        <div class="statement_date_content_row">
          <div class="statement_date_content_col">
            <div class="statement_date_label">期末成本金额</div>
            <div class="statement_date_val">{{divisionMoney(collectData.endStockPrice)}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="statement_chart_box">
      <div class="statement_chart_query">
        <div class="statement_chart_query_box">
          <el-input placeholder="产品名称/编码/规格" v-model="productName" class="input-with-select" size="small">
            <el-select slot="prepend" v-model="warehouseValue" placeholder="仓库名" size="small">
              <el-option
                v-for="item in warehouseOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select slot="prepend" v-model="productValue" placeholder="产品分类" size="small">
              <el-option
                v-for="item in productOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-button slot="append" type="primary" size="small" @click="handleChartChange">查询</el-button>
          </el-input>
        </div>
      </div>
      <div class="statement_chart_content" id="commodityOutAndIn_chart"></div>
    </div>
    <div class="statement_table_box">
      <el-table
        :data="tableData"
        size="mini"
        style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="name"
          label="产品名称">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="productCode"
          label="产品编号">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="depotName"
          label="仓库">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="unitName"
          label="计量单位">
        </el-table-column>
        <el-table-column label="期初">
          <el-table-column
            show-overflow-tooltip
            prop="initialStockNum"
            label="数量"
            :formatter="formatterFigure"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="initialStockPrice"
            label="成本"
            :formatter="formatterFigure">
          </el-table-column>
        </el-table-column>
        <el-table-column label="入库">
          <el-table-column
            show-overflow-tooltip
            prop="putStockNum"
            label="数量"
            :formatter="formatterFigure">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="putStockPrice"
            label="成本"
            :formatter="formatterFigure">
          </el-table-column>
        </el-table-column>
        <el-table-column label="出库">
          <el-table-column
            show-overflow-tooltip
            prop="outStockNum"
            label="数量"
            :formatter="formatterFigure">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="outStockPrice"
            label="成本"
            :formatter="formatterFigure">
          </el-table-column>
        </el-table-column>
        <el-table-column label="期末">
          <el-table-column
            show-overflow-tooltip
            prop="endStockNum"
            label="数量"
            :formatter="formatterFigure">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="endStockPrice"
            label="成本"
            :formatter="formatterFigure">
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        prev-text="上一页"
        next-text="下一页"
        :current-page="paginationOptions.page"
        :page-size="paginationOptions.size"
        :total="paginationOptions.total"
        @current-change="handleCurrentChange">
    </el-pagination>
    </div>
  </div>
</template>

<script>
  import commodityOutAndInApi from '@/network/statement/repertory/commodityOutAndIn/index.js';

  export default{
    data() {
      return{
        dateLoading: false,
        yearOptions: "",
        quarterOptions: "",
        monthOptions: "",
        dayOptions: "",
        yearValue: "",
        quarterValue: "",
        monthValue: "",
        dayValue: "",
        isAll: true,
        pickerValue: "",
        collectData: "",
        warehouseOptions: '',
        productOptions: '',
        productValue: '',
        productName: '',
        warehouseValue: '',
        collectData: "",
        tableData: "",
        paginationOptions: {
          page: 1,
          size: 10,
          total: 0
        }
      }
    },
    mounted() {
      this.getTimeist()
      this.getWarehouseData()
      this.getProductTypeData()
    },
    methods: {
      // 获取年季月
      getTimeist(){
        let t = new Date();
        let y = t.getFullYear()
        let m = t.getMonth()
        let q = parseInt(m/3) + 1
        // 获取本年本季本月
        this.yearValue = y
        this.quarterValue = q
        this.monthValue = m + 1
        // 设置下拉框展示的年
        let maxY = 10, yArr = []
        // 年
        for(let i = 0; i < maxY; i++){
          yArr.push({
            label: (y - i) + "年",
            value: y - i
          })
        }
        this.yearOptions = yArr
        // 季
        this.getQuarterList()
        // 月
        this.getMonthList(q)
        // 天
        this.getDayList(y, m)
        // 获取数据
        this.getList()
        this.getChartList()
        this.getCollectData()
      },
      // 获取季度
      getQuarterList(){
        // 季
        let qArr = []
        qArr.push({
          label: "全部",
          value: null
        })
        for(let i = 1; i <= 4; i++){
          qArr.push({
            label: "第" + i + "季度",
            value: i
          })
        }
        this.quarterOptions = qArr
      },
      // 根据季度获取月份
      getMonthList(q){
        let minM = q*3 - 2
        let maxM = q*3
        let mArr = []
        for(let i = minM; i <= maxM; i++){
          mArr.push({
            label: i + "月",
            value: i
          })
        }
        this.monthOptions = mArr
      },
      // 获取选中月份的天数
      getDayList(y,m){
        let maxD = new Date(y, m, 0).getDate()
        if(this.dayValue > maxD){
          this.dayValue = 1
        }
        let dArr = []
        for(let i = 1; i <= maxD; i++){
          dArr.push({
            label: i + "号",
            value: i
          })
        }
        this.dayOptions = dArr
      },
      // 监听下拉时间变动
      handleDateChange(type){
        this.isAll = true
        this.pickerValue = ""
        if(type === "year"){
          this.monthOptions = ""
          this.dayOptions = ""
          this.quarterValue = ""
          this.monthValue = ""
          this.dayValue = ""
          this.getQuarterList()
        }else if(type === "quarter"){
          this.dayOptions = ""
          this.monthValue = ""
          this.dayValue = ""
          this.getMonthList(this.quarterValue)
        }else if(type === "month"){
          this.dayValue = ""
          this.getDayList(this.yearValue, this.monthValue)
        }
        this.getList()
        this.getCollectData()
        this.getChartList()
      },
      // 查询全部
      handleSearchBtn(){
        this.pickerValue=""
        this.quarterOptions = ""
        this.monthOptions = ""
        this.dayOptions = ""
        this.yearValue = ""
        this.quarterValue = ""
        this.monthValue = ""
        this.dayValue = ""
        this.isAll = false
        this.getList()
        this.getCollectData()
        this.getChartList()
      },
      // 按字段时间范围查询
      onDatePickerChange(){
        this.quarterOptions = ""
        this.monthOptions = ""
        this.dayOptions = ""
        this.yearValue = ""
        this.quarterValue = ""
        this.monthValue = ""
        this.dayValue = ""
        this.isAll = this.pickerValue ? true : false
        this.getList()
        this.getCollectData()
        this.getChartList()
      },
      // 获取仓库列表
      getWarehouseData(){
        commodityOutAndInApi.findAllWarehouse().then((res)=>{
          if(res.code == 200){
            this.warehouseOptions = res.data || ""
          }
        })
      },
      // 获取商品类别
      getProductTypeData(){
        commodityOutAndInApi.findAllProductTypeNoChild().then((res)=>{
          if(res.code == 200){
            this.productOptions = res.data || ""
          }
        })
      }, 
      // 获取商品出入库汇总信息
      getCollectData(){
        const [startTime, endTime] = this.pickerValue
        commodityOutAndInApi.findProductStockReport({
          year: this.yearValue,
          quarter: this.quarterValue,
          month: this.monthValue,
          day: this.dayValue,
          startTime,
          endTime
        }).then((res)=>{
          if(res.code == 200){
            this.collectData = res.data || ""
          }
        })
      },
      // 获取商品出入库数据
      getList(){
        const [startTime, endTime] = this.pickerValue
        commodityOutAndInApi.findProductStockListReport({
          year: this.yearValue,
          quarter: this.quarterValue,
          month: this.monthValue,
          day: this.dayValue,
          startTime,
          endTime,
          houseId: this.warehouseValue,
          productTypeId: this.productValue,
          search: this.productName,
          pageIndex: this.paginationOptions.page,
          pageSize: this.paginationOptions.size,
        }).then((res)=>{
          if(res.code == 200){
            this.tableData = res.data.rows
            this.paginationOptions = {...this.paginationOptions,total:res.data.total}
          }
        })
      },
      // 获取商品出入库图表数据
      getChartList(){
        const [startTime, endTime] = this.pickerValue
        commodityOutAndInApi.findProductStockListReport({
          year: this.yearValue,
          quarter: this.quarterValue,
          month: this.monthValue,
          day: this.dayValue,
          startTime,
          endTime,
          houseId: this.warehouseValue,
          productTypeId: this.productValue,
          search: this.productName,
          pageIndex: 1,
          pageSize: 20,
        }).then((res)=>{
          if(res.code == 200){
            this.chartData = res.data.rows
            this.graphing()
          }
        })
      },
      // 列表字段格式
      formatterFigure(row, column, cellValue){
        return cellValue || 0
      },
       // 表格数据
      _formatJson(filterVal, jsonData){
        let data = jsonData.map(row => filterVal.map(j => {
          let value = ''
          value = row[j]
          return value
        }))
        return data
      },
      // 导出
      exportStatement(){
        const [startTime, endTime] = this.pickerValue
        commodityOutAndInApi.findProductStockListReport({
         year: this.yearValue,
          quarter: this.quarterValue,
          month: this.monthValue,
          day: this.dayValue,
          startTime,
          endTime,
          houseId: this.warehouseValue,
          productTypeId: this.productValue,
          search: this.productName,
          pageIndex: 1,
          pageSize: 10000,
        }).then((res)=>{
          if(res.code == 200){
            import('@/util/Export2Excel').then(excel => {
              let excelObj = {
                name: "产品名称",
                productCode: "产品编号",
                depotName: "仓库",
                initialStockNum: "计量单位",
                taxesAmount: "数量",
                initialStockPrice: "成本",
                putStockNum: "数量",
                putStockPrice: "成本",
                outStockNum: "数量",
                outStockPrice: "成本",
                endStockNum: "数量",
                endStockPrice: "成本",
              }
              const multiHeader = [
                ["产品名称","产品编号","仓库","计量单位","期初","","入库","","出库","","期末",""],
              ]
              const rows = res.data.rows
              const data = this._formatJson(Object.keys(excelObj), rows)
              excel.export_json_to_excel({
                multiHeader: multiHeader,
                header: Object.values(excelObj),
                data,
                filename: '商品出入库统计表',
                autoWidth: true,
                bookType: 'xlsx',
                merges: ['A1:A2','B1:B2','C1:C2','D1:D2','E1:F1','G1:H1','I1:J1','K1:L1']
              })
            })
          }
        })
      },
      // 金额展示添加逗号处理
      divisionMoney(val){
        if(val){
          let str = (val + '').split('.')
          let intSum = str[0].replace(/\B(?=(?:\d{3})+$)/g, ',')
          let dot = str[1] ? '.' + str[1] : ''
          let num = intSum + dot
          return num
        }else{
          return 0
        }
      },
      // 查询图标数据
      handleChartChange(){
        this.getList()
        this.getChartList()
      },
      // 获取x轴数组
      getXName(){
        let axisArr = []
        this.chartData.map(item=>{
          axisArr.push(item.name)
        })
        return axisArr
      },
       // 获取y轴数据
      getYData(){
        let axisData = []
        for(let i = 0; i < 5; i++){
            // 期初数量/入库数量/出库数量/期未数量/期未成本/
          let axisArr = []
          this.chartData.map(item=>{
            switch(i){
              case 0:
                axisArr.push(item.initialStockNum)
                break;
              case 1:
                axisArr.push(item.putStockNum)
                break;
              case 2:
                axisArr.push(item.outStockNum)
                break;
              case 3:
                axisArr.push(item.endStockNum)
                break;
              case 4:
                axisArr.push(item.endStockPrice)
                break;
            }
            
          })
          axisData.push({
            name: i === 0 ? '期初数量' : i === 1 ? '入库数量' : i === 2 ? '出库数量' : i === 3 ? '期未数量': '期未成本',
            type: 'bar',
            data: axisArr,
            animation:true,
          })
        }
        return axisData
      },
      // 生成图表
      graphing(){
        let xAxisData = this.getXName()
        let yAxisData = this.getYData()
        let legendData =  ['期初数量', '入库数量', '出库数量', '期未数量', '期未成本']
        let myChart = this.$echarts.init(document.getElementById('commodityOutAndIn_chart'))
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: legendData
          },
          grid: {
            left: 40,
            right: 10,
            bottom: 0,
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: xAxisData,
              axisTick: {
                alignWithLabel: true,
                show: false
              },
              axisLabel: {
                rotate: 70,
                color: "#000000"
              },
              axisLine:{
                lineStyle: {
                  type: 'dashed',
                  color: '#cccccc',
                }
              },
              minorSplitLine : {
                show: true,
                lineStyle:{
                  type: 'dashed'
                }
              },
              splitLine: {
                show: true,
                lineStyle:{
                  type: 'dashed'
                }
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisTick: {
                alignWithLabel: true,
                show: false,
              },
              axisLabel: {
                color: "#000000"
              },
              axisLine:{
                lineStyle: {
                  type: 'dashed',
                  color: '#cccccc'
                }
              },
              splitLine: {
                lineStyle:{
                  type: 'dashed'
                }
              }
            }
          ],
          color: ['#0091ff', '#5470c6', '#91cc75', '#ee6666', '#73c0de'],
          series: yAxisData
        }
        myChart.setOption(option);
      },
      // 点击页数
      handleCurrentChange(e){
        this.paginationOptions = {...this.paginationOptions, page: e}
        this.getList()
      }
    }
  }
</script>

<style lang="less">
</style>
