
import {request} from "@/network/request.js";
import qs from 'qs';

export default{
  // 查询-条件查询商品出入库列表信息
  findProductStockListReport(data){
    return request({
      method:'POST',
      url:'/report/productStock/findProductStockListReport',
      data
    })
  },
  // 查询-条件查询商品出入库汇总信息
  findProductStockReport(data){
    return request({
      method:'POST',
      url:'/report/productStock/findProductStockReport',
      data
    })
  },
  // 仓库列表
  findAllWarehouse(data){
    return request({
      method:'POST',
      url:'/system/dictionary/findAllWarehouse',
      data
    })
  },
  // 查询-商品类别
  findAllProductTypeNoChild(data){
    return request({
      method:'POST',
      url:'/system/dictionary/findAllProductTypeNoChild',
      data
    })
  },
}